import Api from './api.service';

const ENDPOINT = 'dashboard';

export default {
  getScheduleValues: (params = {}) =>
    Api.get(`${ENDPOINT}/schedule-list-value`, { params }),
  getSchedulesInfo(params = {}) {
    return Api.get(`${ENDPOINT}/schedules`, { params });
  },
  getPatientsTableInfo(params = {}) {
    return Api.get(`${ENDPOINT}/patients-table-info`, { params });
  },
  getPatientsChartsInfo(params = {}) {
    return Api.get(`${ENDPOINT}/patients-charts-info`, { params });
  },
  getDoctorsTableInfo(params = {}) {
    return Api.get(`${ENDPOINT}/doctors-table-info`, { params });
  },
  getDoctorsChartsInfo(params = {}) {
    return Api.get(`${ENDPOINT}/patients-charts-info`, { params });
  },
  getSchedulesBySpecialty(params = {}) {
    return Api.get(`${ENDPOINT}/schedules-by-specialty`, { params });
  },
  getDoctorsStatus(params = {}) {
    return Api.get(`${ENDPOINT}/doctors-status`, { params });
  },
};
